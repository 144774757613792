// -- We don't use ~ here due to the usage on quno-next/scripts/createRoutes.ts
import { createClient } from 'contentful';
import config from 'config';
import { compressQuery } from 'data/graphql/utils/compressQuery';
import { stringify } from 'utils/json';
import type { ILocationPageFields } from 'types/contentful/generated';
import type { AlternateSlug } from 'types/AlternateSlugs';

export const contentfulClient = createClient({
  space: process.env.CONTENTFUL_SPACE || '',
  accessToken: process.env.CONTENTFUL_ACCESS_TOKEN || '',
});

export const contentfulClientPreview = createClient({
  space: process.env.CONTENTFUL_SPACE || '',
  accessToken: process.env.CONTENTFUL_PREVIEW || '',
  host: 'preview.contentful.com',
});

/**
 * Fetches, from contentful, all the pages related to the given Id,
 * filters out pages with locale disabled and finally maps it to an
 * array of objects representing all the slugs of that page per
 * (quno-next) locale. We don't use raw Contentful locales to have more
 * control over the data.
 *
 * @param pageId - Id of the page to return the alternate slugs
 * @param preview - whether or not we are generating alternate pages for a preview
 * @param isDoctorList - do we have a doctors list page
 */
export const getAlternatePages = async (
  pageId: string,
  preview = false,
  isDoctorList = false,
): Promise<AlternateSlug[] | null> => {
  if (!pageId) {
    return null;
  }

  const client = preview ? contentfulClientPreview : contentfulClient;

  const entries = await client.getEntries<ILocationPageFields>({
    locale: '*',
    'sys.id': pageId,
    include: 4,
  });

  if (entries.total != 1) {
    throw new Error(`Expected to receive only one entry for pageId ${pageId}`);
  }

  let mainContentfulLocale = 'en-US';
  const contentfulToMainLocaleMap: Record<string, string> = {};
  for (const entry of config.locales) {
    if (entry.main === config.defaultLocale) {
      mainContentfulLocale = entry.contentful;
    }

    contentfulToMainLocaleMap[entry.contentful] = entry.main;
  }

  const item = entries.items[0];

  const filteredItems = Object.keys(contentfulToMainLocaleMap).filter(
    (contentfulLocale) => {
      const { disableLocale, slug } = item.fields;

      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        disableLocale?.[contentfulLocale] === false ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (contentfulLocale in slug && !disableLocale?.[contentfulLocale])
      );
    },
  );

  return filteredItems.map((contentfulLocale) => {
    const contentTypeId = item.sys.contentType.sys.id;

    const slugMap = item.fields.slug as unknown as Record<string, string>;

    const localizedSlug =
      slugMap[contentfulLocale] || slugMap[mainContentfulLocale];

    const { speciality, treatment } = item.fields;

    // TODO: remove the @ts-ignore comments from this legacy code and fix it
    const specialitySlug =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      speciality?.[mainContentfulLocale].fields.slug[contentfulLocale];

    const treatmentSlug =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      treatment?.[mainContentfulLocale].fields.slug[contentfulLocale];

    let slug = localizedSlug;

    if (
      (contentTypeId === 'locationPage' || isDoctorList) &&
      specialitySlug &&
      treatmentSlug
    ) {
      slug = `${specialitySlug}/${treatmentSlug}/${localizedSlug}`;
    } else if (
      contentTypeId === 'treatmentPage' &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      speciality?.[contentfulLocale]
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      slug = `${speciality[contentfulLocale].fields.slug[contentfulLocale]}/${localizedSlug}`;
    }

    return {
      locale: contentfulToMainLocaleMap[contentfulLocale],
      slug,
    };
  });
};

export const fetchContentfulGql = async ({
  query,
  variables,
  preview = false,
}: {
  query: string;
  variables: Record<string, unknown>;
  preview?: boolean;
}): // eslint-disable-next-line @typescript-eslint/no-explicit-any
Promise<any> => {
  try {
    const res = await fetch(
      `https://graphql.contentful.com/content/v1/spaces/${process.env.CONTENTFUL_SPACE}/`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${
            preview
              ? process.env.CONTENTFUL_PREVIEW
              : process.env.CONTENTFUL_ACCESS_TOKEN
          }`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: compressQuery(query),
          variables,
        }),
      },
    );

    const result = await res.json();
    return result;
  } catch (error) {
    console.error(stringify(error as Error));
  }
};
